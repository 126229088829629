import React, { useEffect, useState, useRef } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import {
  Button,
  TextField,
  Grid,
  ListItem,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@material-ui/core';
import * as firebase from 'firebase';
import 'firebase/storage';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import useStyles from './styles';
import { getApi, postApi, putApi } from '../../services/Services';
import { IsEmailValid, getCustomID } from '../../utills';
import AddUserModal from '../../components/AddUserModal';
import { useRobotState } from '../../context/RobotContext';
import { currencyList } from '../../assest/data/currencyList';

const initialValues = {
  id: getCustomID(),
  robotName: '',
  user: '',
  status: '',
  currency: '',
  type: '',
  project_id: '',
  private_key_id: '',
  private_key: '',
  client_email: '',
  client_id: '',
  auth_uri: '',
  token_uri: '',
  auth_provider_x509_cert_url: '',
  client_x509_cert_url: '',
  firebaseDatabaseUrl: '',
  selectedUsers: [],
  collection_token: '',
  bucketName: ''
};

const validationSchema = Yup.object().shape({
  id: Yup.string().label('id').required('Required'),
  robotName: Yup.string().label('robotName').required('Required'),
  user: Yup.string().label('user'),
  selectedUsers: Yup.array().label('user'),
  status: Yup.string().label('status').required('Required'),
  currency: Yup.string().label('currency').required('Required'),
  type: Yup.string().label('type').required('Required'),
  project_id: Yup.string().label('project_id').required('Required'),
  private_key_id: Yup.string().label('private_key_id').required('Required'),
  private_key: Yup.string().label('private_key').required('Required'),
  client_email: Yup.string()
    .required('Required')
    .test((value) => value && IsEmailValid(value)),
  client_id: Yup.string().label('client_id').required('Required'),
  auth_uri: Yup.string().label('auth_uri').required('Required'),
  token_uri: Yup.string().label('token_uri').required('Required'),
  auth_provider_x509_cert_url: Yup.string()
    .label('auth_provider_x509_cert_url')
    .required('Required'),
  client_x509_cert_url: Yup.string()
    .label('client_x509_cert_url')
    .required('Required'),
  firebaseDatabaseUrl: Yup.string().label('firebaseDatabaseUrl'),
  collection_token: Yup.string().label('collection_token'),
  bucketName: Yup.string().label('bucketName')
});

// Currency dropdown list
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function RobotForm(props) {
  const classes = useStyles();
  const { history, match } = props;
  const { params } = match;
  const robotId = params.id;
  const { t } = useTranslation('common');
  const [type, setType] = useState('add');
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const timerRef = useRef(null);
  const jsonInputRef = useRef(null);
  const [userData, setUserData] = useState([]);
  const { allRobots } = useRobotState();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const isRobotNameAlreadyExist = (values) => {
    let isExist = false;
    if (allRobots.length > 0) {
      allRobots.forEach((item) => {
        if (item.robotName === values.robotName && item.id !== values.id)
          isExist = true;
      });
    }
    return isExist;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let selectedUser = userData.filter(
        (item) => item.uid === values.user && item
      );
      selectedUser = selectedUser[0];
      values.userDetails = selectedUser;
      const isRobotNameExist = await isRobotNameAlreadyExist(values);
      if (isRobotNameExist) {
        utility.snack(
          'Please change the robot name.it is already existed.',
          'error',
          3000
        );
        return false;
      }
      if (selectedUsers.length === 0) {
        utility.snack('Please select any user.', 'error', 3000);
        return false;
      }
      utility.loading(true);

      const multiLang = { multiLanguage: values.multiLanguage || 'single' };
      const robotDetails = { ...values, ...multiLang, selectedUsers };

      if (type === 'add') {
        postApi('/saverobot', { values: robotDetails })
          .then(() => {
            utility.loading(false);
            utility.snack('Successfully Save');
            timerRef.current = setTimeout(() => {
              history.push('/app/robots');
            }, 1000);
          })
          .catch((error) => {
            console.error(error);
            utility.loading(false);
            utility.snack('Something went wrong please try again.', 'error');
          });
      } else {
        putApi('/editrobot?id=' + values.id, { values: robotDetails })
          .then(() => {
            utility.loading(false);
            utility.snack('Successfully update');
            timerRef.current = setTimeout(() => {
              history.push('/app/robots');
            }, 1000);
          })
          .catch((error) => {
            console.error(error);
            utility.loading(false);
            utility.snack('Something went wrong please try again.', 'error');
          });
      }
    },
    handleBlur: async () => {
      console.log('values');
    }
  });

  async function getUsersList() {
    utility.loading(true);
    const usersRef = firebase.database().ref('users');
    usersRef.on('value', function (dataSnapshot) {
      const result = dataSnapshot.val();
      const userData1 = JSON.parse(JSON.stringify(userData));
      for (const x in result) {
        userData1.push({
          id: result[x].id,
          uid: result[x].uid,
          username: result[x].username
        });
      }
      setUserData(userData1);
      utility.loading(false);
    });
  }

  const getRobotInfo = async () => {
    utility.loading(true);
    if (robotId) {
      setType('edit');
      const response = await getApi('/getrobotbyid', { robotId });
      if (response.statusCode === 200) {
        const robot = response.data.robot;
        formik.setFieldValue('id', robot.id);
        formik.setFieldValue('robotName', robot.robotName);
        formik.setFieldValue('user', robot.user);
        formik.setFieldValue('status', robot.status);
        formik.setFieldValue('multiLanguage', robot.multiLanguage);
        formik.setFieldValue('currency', robot?.currency);
        formik.setFieldValue('type', robot.type);
        formik.setFieldValue('project_id', robot.project_id);
        formik.setFieldValue('private_key_id', robot.private_key_id);
        formik.setFieldValue('private_key', robot.private_key);
        formik.setFieldValue('client_email', robot.client_email);
        formik.setFieldValue('client_id', robot.client_id);
        formik.setFieldValue('auth_uri', robot.auth_uri);
        formik.setFieldValue('token_uri', robot.token_uri);
        formik.setFieldValue(
          'auth_provider_x509_cert_url',
          robot.auth_provider_x509_cert_url
        );
        formik.setFieldValue(
          'client_x509_cert_url',
          robot.client_x509_cert_url
        );
        formik.setFieldValue(
          'firebaseDatabaseUrl',
          robot.firebaseDatabaseUrl || ''
        );
        formik.setFieldValue('collection_token', robot.collection_token || '');
        formik.setFieldValue('bucketName', robot.bucketName || '');
        if (robot && robot.selectedUsers) {
          setSelectedUsers(robot.selectedUsers);
        }

        utility.loading(false);
      } else {
        utility.loading(false);
        history.push('/app/robots');
        utility.snack('Data not found.', 'error');
      }
    } else {
      const id = getCustomID();
      formik.setFieldValue('id', id);
    }
  };
  useEffect(() => {
    getUsersList();

    if (robotId) {
      getRobotInfo();
    } else {
      const id = getCustomID();
      formik.setFieldValue('id', id);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handleImportJson = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      const importedCredentials = JSON.parse(e.target.result);
      if (importedCredentials && importedCredentials.client_id) {
        formik.setFieldValue('type', importedCredentials.type);
        formik.setFieldValue('project_id', importedCredentials.project_id);
        formik.setFieldValue(
          'private_key_id',
          importedCredentials.private_key_id
        );
        formik.setFieldValue('private_key', importedCredentials.private_key);
        formik.setFieldValue('client_email', importedCredentials.client_email);
        formik.setFieldValue('client_id', importedCredentials.client_id);
        formik.setFieldValue('auth_uri', importedCredentials.auth_uri);
        formik.setFieldValue('token_uri', importedCredentials.token_uri);
        formik.setFieldValue(
          'auth_provider_x509_cert_url',
          importedCredentials.auth_provider_x509_cert_url
        );
        formik.setFieldValue(
          'client_x509_cert_url',
          importedCredentials.client_x509_cert_url
        );

        utility.snack('Successfully imported credentials from JSON');
      } else if (importedCredentials.client_id) {
        utility.snack('Please import correct JSON file', 'error');
      }
    };
  };

  return (
    <div>
      <Header
        title={robotId ? t('robot.editRobot') : t('robot.createRobot')}
        showRobotList={false}
      />
      <form
        className="presenter-form"
        style={{ width: '100%', maxWidth: 900 }}
        onSubmit={formik.handleSubmit}
      >
        <Paper style={{ padding: 15, width: '100%' }}>
          <h2 id="simple-modal-title">
            {robotId ? t('robot.editRobot') : t('robot.createRobot')}
          </h2>
          <TextField
            label={t('robot.name')}
            id="robotName"
            name="robotName"
            variant="outlined"
            margin="normal"
            type="text"
            fullWidth
            error={
              formik.errors.robotName && formik.touched.robotName ? true : null
            }
            helperText={
              formik.errors.robotName && formik.touched.robotName
                ? formik.errors.robotName
                : ''
            }
            onChange={formik.handleChange}
            value={formik.values.robotName}
            onBlur={formik.handleBlur}
          />
          <Grid container spacing={4}>
            <Grid item xs={12} md={8} lg={8}>
              <ListItem className={classes.noHorizontalPadding}>
                <FormControl className={classes.formControlModal}>
                  <Multiselect
                    options={userData}
                    onSelect={(updatedOptions) =>
                      setSelectedUsers(updatedOptions)
                    }
                    onRemove={(updatedOptions) =>
                      setSelectedUsers(updatedOptions)
                    }
                    displayValue={'username'}
                    showCheckbox={true}
                    selectedValues={selectedUsers}
                    placeholder="Select to add user"
                    style={{
                      searchBox: {
                        minHeight: '50px'
                      },
                      chips: {
                        overflow: 'hidden'
                      }
                    }}
                  />
                </FormControl>
                <div className={classes.addUserBtn}>
                  <AddUserModal />
                </div>
              </ListItem>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <ListItem className={classes.noHorizontalPadding}>
                <FormControl style={{ minWidth: 100, flex: 1 }}>
                  <InputLabel id="status">{t('robot.status')}</InputLabel>
                  <Select
                    label={t('robot.status')}
                    id="status"
                    name="status"
                    error={
                      formik.errors.status && formik.touched.status
                        ? true
                        : null
                    }
                    helperText={
                      formik.errors.status && formik.touched.status
                        ? formik.errors.status
                        : ''
                    }
                    onChange={formik.handleChange}
                    value={formik.values.status}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value={'on'}>ON</MenuItem>
                    <MenuItem value={'off'}>OFF</MenuItem>
                  </Select>
                </FormControl>
              </ListItem>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={8}>
              <ListItem className={classes.noHorizontalPadding}>
                <FormControl className={classes.formControlModalFullWidth}>
                  <InputLabel id="status">{t('robot.currency')}</InputLabel>
                  <Select
                    label={t('robot.currency')}
                    id="currency"
                    name="currency"
                    error={
                      formik.errors.currency && formik.touched.currency
                        ? true
                        : null
                    }
                    helperText={
                      formik.errors.currency && formik.touched.currency
                        ? formik.errors.currency
                        : ''
                    }
                    onChange={formik.handleChange}
                    value={formik.values.currency}
                    onBlur={formik.handleBlur}
                    MenuProps={MenuProps}
                  >
                    {currencyList?.length > 0 &&
                      currencyList?.map((item, index) => (
                        <MenuItem key={index} value={item?.code}>
                          {item?.name_plural} - {item?.symbol}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </ListItem>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <ListItem className={classes.noHorizontalPadding}>
                <FormControl style={{ minWidth: 100, flex: 1 }}>
                  <InputLabel id="multiLanguage">
                    {t('robot.multiLanguage')}
                  </InputLabel>
                  <Select
                    label={t('robot.multiLanguage')}
                    id="multiLanguage"
                    name="multiLanguage"
                    error={
                      formik.errors.multiLanguage &&
                      formik.touched.multiLanguage
                        ? true
                        : null
                    }
                    helperText={
                      formik.errors.multiLanguage &&
                      formik.touched.multiLanguage
                        ? formik.errors.multiLanguage
                        : ''
                    }
                    onChange={formik.handleChange}
                    value={`${formik.values.multiLanguage || 'single'}`}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value={'single'}>{t('single')}</MenuItem>
                    <MenuItem value={'dual'}>{t('dual')}</MenuItem>
                  </Select>
                </FormControl>
              </ListItem>
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <label style={{ fontSize: '20px' }}>
              {t('robot.addCredientials')}
            </label>
            <div>
              <input
                accept="application/json"
                style={{ display: 'none' }}
                ref={jsonInputRef}
                onChange={handleImportJson}
                type="file"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => jsonInputRef.current.click()}
              >
                Import from JSON
              </Button>
            </div>
          </div>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label={t('robot.type')}
                id="type"
                name="type"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={formik.errors.type && formik.touched.type ? true : null}
                helperText={
                  formik.errors.type && formik.touched.type
                    ? formik.errors.type
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.type}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label={t('robot.project_id')}
                id="project_id"
                name="project_id"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={
                  formik.errors.project_id && formik.touched.project_id
                    ? true
                    : null
                }
                helperText={
                  formik.errors.project_id && formik.touched.project_id
                    ? formik.errors.project_id
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.project_id}
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label={'private_key_id'}
                id="private_key_id"
                name="private_key_id"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={
                  formik.errors.private_key_id && formik.touched.private_key_id
                    ? true
                    : null
                }
                helperText={
                  formik.errors.private_key_id && formik.touched.private_key_id
                    ? formik.errors.private_key_id
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.private_key_id}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label={t('robot.private_key')}
                id="private_key"
                name="private_key"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={
                  formik.errors.private_key && formik.touched.private_key
                    ? true
                    : null
                }
                helperText={
                  formik.errors.private_key && formik.touched.private_key
                    ? formik.errors.private_key
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.private_key}
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label={t('robot.client_email')}
                id="client_email"
                name="client_email"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={
                  formik.errors.client_email && formik.touched.client_email
                    ? true
                    : null
                }
                helperText={
                  formik.errors.client_email && formik.touched.client_email
                    ? formik.errors.client_email
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.client_email}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label={t('robot.client_id')}
                id="client_id"
                name="client_id"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={
                  formik.errors.client_id && formik.touched.client_id
                    ? true
                    : null
                }
                helperText={
                  formik.errors.client_id && formik.touched.client_id
                    ? formik.errors.client_id
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.client_id}
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label={t('robot.auth_uri')}
                id="auth_uri"
                name="auth_uri"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={
                  formik.errors.auth_uri && formik.touched.auth_uri
                    ? true
                    : null
                }
                helperText={
                  formik.errors.auth_uri && formik.touched.auth_uri
                    ? formik.errors.auth_uri
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.auth_uri}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label={t('robot.token_uri')}
                id="token_uri"
                name="token_uri"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={
                  formik.errors.token_uri && formik.touched.token_uri
                    ? true
                    : null
                }
                helperText={
                  formik.errors.token_uri && formik.touched.token_uri
                    ? formik.errors.token_uri
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.token_uri}
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label={t('robot.auth_provider_x509_cert_url')}
                id="auth_provider_x509_cert_url"
                name="auth_provider_x509_cert_url"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={
                  formik.errors.auth_provider_x509_cert_url &&
                  formik.touched.auth_provider_x509_cert_url
                    ? true
                    : null
                }
                helperText={
                  formik.errors.auth_provider_x509_cert_url &&
                  formik.touched.auth_provider_x509_cert_url
                    ? formik.errors.auth_provider_x509_cert_url
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.auth_provider_x509_cert_url}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label={t('robot.client_x509_cert_url')}
                id="client_x509_cert_url"
                name="client_x509_cert_url"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={
                  formik.errors.client_x509_cert_url &&
                  formik.touched.client_x509_cert_url
                    ? true
                    : null
                }
                helperText={
                  formik.errors.client_x509_cert_url &&
                  formik.touched.client_x509_cert_url
                    ? formik.errors.client_x509_cert_url
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.client_x509_cert_url}
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
          <Divider style={{ marginTop: 20 }} />
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label={t('robot.firebaseDatabaseUrl')}
                id="firebaseDatabaseUrl"
                name="firebaseDatabaseUrl"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={
                  formik.errors.firebaseDatabaseUrl &&
                  formik.touched.firebaseDatabaseUrl
                    ? true
                    : null
                }
                helperText={
                  formik.errors.firebaseDatabaseUrl &&
                  formik.touched.firebaseDatabaseUrl
                    ? formik.errors.firebaseDatabaseUrl
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.firebaseDatabaseUrl}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label={t('robot.collection_token')}
                id="collection_token"
                name="collection_token"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={
                  formik.errors.collection_token &&
                  formik.touched.collection_token
                    ? true
                    : null
                }
                helperText={
                  formik.errors.collection_token &&
                  formik.touched.collection_token
                    ? formik.errors.collection_token
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.collection_token}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                label={t('robot.bucket_name')}
                id="bucketName"
                name="bucketName"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.bucketName}
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
          <ListItem>
            <Button variant="contained" color="primary" type="submit">
              {t('save')}{' '}
            </Button>
          </ListItem>
        </Paper>
      </form>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';

// styles
import useStyles from './styles';

// components
import { Typography } from '../../components/Wrappers';
import Charts from '../charts/Charts';
import { Grid } from '@material-ui/core';
import Widget from '../../components/Widget/Widget';
import FunctionLogs from '../../components/FunctionLogs';
import Header from '../../components/Header';
import { getApi } from '../../services/Services';
import { getTimeZoneData } from '../../utills';
import { GetSeletedRobot } from '../../utills';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import { DATA_1, DATA_7, DATA_30 } from './data';
import { ExportJsonCsv } from 'react-export-json-csv';
import * as Icons from '@material-ui/icons';

const defaultData = {
  1: DATA_1,
  7: DATA_7,
  30: DATA_30
};

export default function Dashboard() {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const [logs, setLogs] = useState([]);
  const [chartData, setChartData] = useState(defaultData);
  let timestampsWeek = [];
  let humanTimestampsWeek = [];
  let timestampsMonth = [];
  let humanTimestampsMonth = [];
  let timestampsYesterday = [];
  let humanTimestampsYesterday = [];

  const resetChartTimeStampDate = () => {
    timestampsWeek = [];
    humanTimestampsWeek = [];
    timestampsMonth = [];
    humanTimestampsMonth = [];
    timestampsYesterday = [];
    humanTimestampsYesterday = [];
    const lastDay = moment(
      new Date().toLocaleString('en-US', getTimeZoneData())
    )
      .subtract(1, 'days')
      .endOf('day')
      .format('YYYY-MM-DD HH:MM:ss');
    for (let monthIndex = 0; monthIndex < 30; monthIndex++) {
      timestampsMonth.push(
        moment(new Date().toLocaleString('en-US', getTimeZoneData()))
          .subtract(monthIndex + 1, 'days')
          .startOf('day')
          .valueOf()
      );
      humanTimestampsMonth.push(
        moment(new Date().toLocaleString('en-US', getTimeZoneData()))
          .subtract(monthIndex + 1, 'days')
          .format('YYYY-MM-DD HH:mm:ss')
      );

      if (monthIndex + 1 <= 24) {
        timestampsYesterday.push(
          moment(lastDay, 'YYYY-MM-DD HH:MM:ss', getTimeZoneData().timeZone)
            .subtract(monthIndex + 1, 'hours')
            .valueOf()
        );
        humanTimestampsYesterday.push(
          moment(lastDay, 'YYYY-MM-DD HH:MM:ss', getTimeZoneData().timeZone)
            .subtract(monthIndex + 1, 'hours')
            .format('YYYY-MM-DD HH:mm:ss')
        );
      }

      if (monthIndex + 1 < 7) {
        timestampsWeek.push(
          moment(new Date().toLocaleString('en-US', getTimeZoneData()))
            .subtract(monthIndex + 1, 'days')
            .startOf('day')
            .valueOf()
        );
        humanTimestampsWeek.push(
          moment(new Date().toLocaleString('en-US', getTimeZoneData()))
            .subtract(monthIndex + 1, 'days')
            .format('YYYY-MM-DD HH:mm:ss')
        );
      }
    }
  };

  const generateChartData = (data) => {
    const defaultYesterdayHistorical = [
      0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
      0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0
    ];
    const defaultWeekHistorical = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0];
    const defaultMonthHistorical = [
      0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
      0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0
    ];

    const yesterday = moment(
      new Date().toLocaleString('en-US', getTimeZoneData())
    )
      .subtract(1, 'days')
      .startOf('day')
      .valueOf();
    const lastWeek = moment(
      new Date().toLocaleString('en-US', getTimeZoneData())
    )
      .subtract(7, 'days')
      .startOf('day')
      .valueOf();
    const lastMonth = moment(
      new Date().toLocaleString('en-US', getTimeZoneData())
    )
      .subtract(1, 'months')
      .startOf('day')
      .valueOf();

    const historicalWeek = defaultWeekHistorical;
    const historicalMonth = defaultMonthHistorical;
    const historicalYesterDay = defaultYesterdayHistorical;

    data.forEach((item) => {
      const dayStartTimestamp = moment(item.m).startOf('day').valueOf();

      // chart Data for week - start
      if (item.m > lastWeek && timestampsWeek.length > 0) {
        const find7Index = timestampsWeek.indexOf(dayStartTimestamp);
        if (find7Index !== -1)
          historicalWeek[find7Index] = historicalWeek[find7Index] + 1;
      }
      // chart Data for week - end

      // chart Data for month - start
      if (item.m > lastMonth && timestampsMonth.length > 0) {
        const findMonthIndex = timestampsMonth.indexOf(dayStartTimestamp);
        if (findMonthIndex !== -1)
          historicalMonth[findMonthIndex] = historicalMonth[findMonthIndex] + 1;
      }
      // chart Data for month - end

      // chart Data for yesterday - start
      if (item.m > yesterday && timestampsYesterday.length > 0) {
        const timestamp = moment(item.m);
        const remainder = 30 - (timestamp.minute() % 30); //Rounding time to nearest 30 min gap. e.g 12:37 to 12:30
        const roundedTime = moment(timestamp)
          .add(remainder, 'minutes')
          .valueOf();
        const findYesterdayIndex = timestampsYesterday.indexOf(roundedTime);
        if (findYesterdayIndex !== -1)
          historicalYesterDay[findYesterdayIndex] =
            historicalYesterDay[findYesterdayIndex] + 1;
      }
      // chart Data for yesterday - end
    });

    chartData[1].num_users.timestamps = timestampsYesterday;
    chartData[1].num_users[24].historical = historicalYesterDay;
    chartData[1].human_timestamps = humanTimestampsYesterday;
    chartData[7].num_users.timestamps = timestampsWeek;
    chartData[7].num_users[24].historical = historicalWeek;
    chartData[7].human_timestamps = humanTimestampsWeek;
    chartData[30].num_users.timestamps = timestampsMonth;
    chartData[30].num_users[24].historical = historicalMonth;
    chartData[30].human_timestamps = humanTimestampsMonth;

    setChartData({ ...chartData });
  };

  const getLogs = async (robotID) => {
    const selectedRobotID = robotID ? robotID : GetSeletedRobot();
    if (!selectedRobotID) return false;
    utility.loading(true);

    try {
      const logsResponse = await getApi(
        `/get-analytics?selectedRobot=${selectedRobotID}`
      );

      if (
        logsResponse.statusCode === 200 &&
        logsResponse.data &&
        logsResponse.data.length > 0
      ) {
        resetChartTimeStampDate();
        setLogs(logsResponse.data);

        generateChartData(logsResponse.data);
      } else {
        setLogs([]);
        resetChartTimeStampDate();
      }

      utility.loading(false);
    } catch (e) {
      utility.loading(false);
    }
  };

  useEffect(() => {
    (async () => {
      resetChartTimeStampDate();
      await getLogs();
    })();
  }, []);

  const onHeaderRobotSelected = (robotId) => {
    setChartData(defaultData);
    getLogs(robotId);
  };

  const csvHeaders = [
    {
      key: 'd',
      name: 'Date'
    },
    {
      key: 'q',
      name: 'Query'
    },
    {
      key: 'r',
      name: 'Response'
    }
  ];

  return (
    <>
      <Header
        title={t('dashboard.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      <Typography
        size="xl"
        align="left"
        weight="medium"
        className={classes.dashboardTitle}
      >
        {t('dashboard.subtitle')}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            title={t('dashboard.widgets.chart.title')}
            noBodyPadding
            upperTitle
            disableWidgetMenu
          >
            <Charts chartData={chartData} />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget
            title={t('dashboard.widgets.functionLogs.title')}
            noBodyPadding
            upperTitle
            disableWidgetMenu
          >
            <div>
              {logs && logs.length > 0 && (
                <ExportJsonCsv
                  headers={csvHeaders}
                  items={logs}
                  fileTitle="conversation-history.csv"
                  className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary"
                  style={{ margin: '15px 10px' }}
                >
                  <Icons.CloudDownload
                    fontSize="small"
                    style={{ marginRight: '5px' }}
                  />{' '}
                  Export CSV
                </ExportJsonCsv>
              )}
            </div>
            <FunctionLogs logs={logs} />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
